<template>
  <div class="main__content">
    <div class="table__header_bar control-panel">
      <div>
        <InputSearch isFilter @detectSearchValue="searchTableHandler" />
      </div>
      <Button isControl iconName="plus" @click.native="createPresentation">
        {{ $t('sections.add_presentation') }}
      </Button>
    </div>
    <Table
      v-if="!!getPresentationsTableAdmin"
      :content="getPresentationsTableAdmin"
      @tableHandler="tableHandler"
    />
    <v-skeleton-loader v-else type="table" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Table from '@/elements/Table';
export default {
  components: {
    Table,
  },
  data() {
    return {};
  },

  created() {
    this.$store.dispatch('presentationsTableContentAdmin');
  },

  computed: {
    ...mapGetters(['getPresentationsTableAdmin']),
  },
  methods: {
    createPresentation() {
      this.$router.push({
        name: 'content-administrator_presentation-create',
        params: { activity: 'create' },
      });
    },
    searchTableHandler(val) {
      this.$store.commit('SET_TABLE_SORT_DATA', { search: val });
      this.$store.dispatch('presentationsTableContentAdmin');
    },
    tableHandler() {
      this.$store.dispatch('presentationsTableContentAdmin');
    },
  },
};
</script>
